<template>
  <v-navigation-drawer
    v-if="data"
    :key="data.uuid"
    v-model="open"
    :width="$vuetify.breakpoint.mobile ? '100%' : 350"
    app
    right
    temporary
    class="elevation-8"
  >
    <v-card-title>
      <div>
        <v-icon left>
          fal {{ data?.uuid ? 'fa-user-edit' : 'fa-user-plus' }}
        </v-icon>
        {{ data?.uuid ? `${data.first_name} ${data.last_name}` : 'Create User' }}
      </div>
      <v-spacer />
      <v-btn
        icon
        tile
        right
        color="grey"
        @click.stop="$emit('close-drawer')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-tabs
      v-model="tab"
      background-color="primary lighten-1"
      grow
      dark
    >
      <v-tab>
        <v-icon v-text="'fal fa-circle-info'" />
      </v-tab>
      <v-tab>
        <v-icon v-text="'fal fa-lock-keyhole'" />
      </v-tab>
    </v-tabs>
    <v-form
      ref="userForm"
      v-model="valid"
      @submit.prevent="updateUser"
    >
      <v-tabs-items v-model="tab">
        <v-container class="spy-0">
          <v-tab-item class="pt-4">
            <v-row>
              <v-col cols="12">
                <template
                  v-if="data.uuid"
                >
                  <div class="text-caption">
                    Client
                  </div>
                  <client-icon
                    :key="data.client.uuid"
                    :icon="data.client.icon"
                  />
                  <span
                    class="text-h7 ml-2"
                    v-text="data.client.name"
                  />
                </template>
                <client-filter
                  v-else
                  :key="data.uuid ? data.uuid : 'new_user'"
                  :value.sync="data.client"
                  :multiple="false"
                  :clearable="data.uuid ? false : true"
                  :error-messages.sync="errors.client"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="data.prefix"
                  :items="prefixes"
                  label="Prefix"
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="data.first_name"
                  :label="$t('first name') | capitalize"
                  :rules="rules.required"
                  class="required"
                  dense
                  :error-messages.sync="errors.first_name"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="data.last_name"
                  :label="$t('last name') | capitalize"
                  :rules="rules.required"
                  class="required"
                  dense
                  :error-messages.sync="errors.last_name"
                />
              </v-col>

              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="data.email"
                  prepend-icon="fal fa-at"
                  :label="$t('email')"
                  type="email"
                  dense
                  :error-messages.sync="errors.email"
                />
              </v-col>

              <v-col
                cols="12"
              >
                <v-text-field
                  v-model.number="data.phone"
                  prepend-icon="fal fa-phone"
                  :label="$t('phone') | capitalize"
                  type="tel"
                  dense
                />
              </v-col>
              <v-divider />
              <v-col
                v-if="data.uuid"
                cols="12"
              >
                <facility-filter
                  :key="data.uuid"
                  :value.sync="data.facilities"
                  :params="{ client: data.client.uuid }"
                  return-object
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="pt-4">
            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="data.roles"
                  label="Roles"
                  :items="roles"
                  multiple
                  item-text="name"
                  return-object
                  :rules="rules.required"
                  class="required"
                  chips
                  dense
                  required
                />
              </v-col>
            </v-row>
            <v-row
              v-for="(permissions, key) in permissions"
              :key="key"
            >
              <v-col
                cols="12"
                class="mb-0 py-0 text-overline"
                v-text="key"
              />
              <v-col
                v-for="(permission, index) in permissions"
                :key="index"
                class="mt-0 pt-0"
              >
                <v-checkbox
                  v-model="computedPermissions"
                  :label="permission.split('.')[1]| capitalize"
                  :value="permission"
                  hide-details
                  dense
                  :disabled="permissionsFromRoles.includes(permission)"
                />
              </v-col>
              <v-col cols="12">
                <v-divider />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-container>
      </v-tabs-items>
    </v-form>
    <template #append>
      <v-col
        v-if="!data.uuid"
        class="pb-0"
      >
        <v-checkbox
          v-model="data.welcome_email"
          label="Send Welcome Email"
          dense
        />
      </v-col>
      <v-divider />
      <v-col>
        <v-btn
          :loading="loading"
          type="submit"
          color="success"
          block
          @click="updateUser"
        >
          {{ data?.uuid ? 'Update' : 'Create' }} User
        </v-btn>
      </v-col>
      <v-col
        v-if="data.uuid && $auth.check({ users: 'edit' })"
        class="pt-0"
      >
        <v-btn
          color="tertiary"
          dark
          block
          outlined
          @click="welcomeEmail"
        >
          <v-icon
            small
            left
          >
            fal fa-hand-wave
          </v-icon>
          Send Welcome Email
        </v-btn>
      </v-col>
      <v-col
        v-if="data.uuid && $auth.check({ users: 'edit' })"
        class="pt-0"
      >
        <v-btn
          color="tertiary"
          dark
          block
          outlined
          @click="resetPassword"
        >
          <v-icon
            small
            left
          >
            fal fa-key
          </v-icon>
          Send Password Reset
        </v-btn>
      </v-col>
      <v-col
        v-if="data.uuid && $auth.check({ users: 'edit' })"
        class="pt-0"
      >
        <v-btn
          color="error"
          block
          outlined
          @click="deleteUser"
        >
          <v-icon
            small
            left
          >
            fal fa-trash
          </v-icon>
          Delete User
        </v-btn>
      </v-col>
    </template>
  </v-navigation-drawer>
</template>
<script>
  export default {
    components: {
      ClientFilter: () => import('@/components/tables/filters/ClientFilter.vue'),
      FacilityFilter: () => import('@/components/tables/filters/FacilityFilter.vue'),
      ClientIcon: () => import('@/components/ClientIcon.vue'),
    },
    props: {
      open: Boolean,
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data: () => ({
      tab: null,
      valid: false,
      loading: false,
      prefixes: [
        'Dr.',
        'Prof.',
        'Mr.',
        'Mrs.',
        'Ms.',
        'Miss',
      ],
      roles: [],
      permissions: [],
      rules: {
        required: [
          v => !!v || this.$t('required'),
        ],
      },
      errors: {},
    }),
    computed: {
      permissionsFromRoles () {
        if (this.data.roles) {
          var user_roles = this.data.roles.map(x => x.id)
          return this.roles.filter(x => user_roles.includes(x.id)).flatMap(x => x.permissions.map(x => x.name))
        } else {
          return []
        }
      },
      computedPermissions: {
        get () {
          var permissions = this.data.permissions && this.data.permissions[0] && this.data.permissions[0]?.id ? this.data.permissions.map(x => x.name) : this.data.permissions
          return this.permissionsFromRoles.concat(permissions)
        },
        set (val) {
          this.data.permissions = val.filter(x => !this.permissionsFromRoles.includes(x))
        },
      },
    },
    mounted() {
      this.axios.get('admin/access/roles')
        .then((response) => {
          this.roles = response.data
        }).catch(error => {
          this.$toast.error(error)
        })

      this.axios.get('admin/access/permissions')
        .then((response) => {
          this.permissions = response.data
        }).catch(error => {
          this.$toast.error(error)
        })
    },
    methods: {
      updateUser () {
        if (this.valid) {
          this.loading = true
          // const formData = new FormData()
          // for (const [key, value] of Object.entries(this.data)) {
          //   formData.append(key, value)
          // }
          // if (this.data.photo && typeof this.data.photo === 'object') {
          //   formData.append('files', this.data.photo, this.data.photo.name)
          // }
          // if (this.data.uuid) {
          //   formData.append('_method', 'patch')
          // }
          this.axios.request({
            method: this.data.uuid ? 'patch' : 'post',
            url: this.data.uuid ? `admin/users/${this.data.uuid}` : 'admin/users',
            data: this.data,
          })
            .then(() => {
              this.$root.$emit('fetch-users')
              this.$emit('close-drawer')
              this.$toast.success('User updated')
            })
            .catch(error => {
              if (error.response.status === 422) {
                this.errors = error.response.data
              } else {
                this.$toast.error(error)
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
      welcomeEmail () {
        this.$dialog.confirm({
          text: 'Send welcome email to user?',
          title: 'Welcome Email',
        })
          .then((res) => {
            if (res) {
              this.axios.post('auth/welcome', { email: this.data.email })
                .then(() => {
                  this.$toast.success('Welcome email sent')
                })
                .catch(error => {
                  this.$toast.error(error)
                })
            }
          })
      },
      resetPassword () {
        this.$dialog.confirm({
          text: 'Send password reset email to user?',
          title: this.$t('Reset Password'),
        })
          .then((res) => {
            if (res) {
              this.axios.post('auth/password', { email: this.data.email })
                .then(() => {
                  this.$toast.success('Password reset info sent')
                })
                .catch(error => {
                  this.$toast.error(error)
                })
            }
          })
      },
      deleteUser () {
        this.$dialog.warning({
          text: 'Do you really want to delete this user?',
          title: 'Delete User',
          icon: 'fas fa-exclamation-triangle mr-2',
          actions: {
            true: {
              text: 'Yes', color: 'red',
            },
            false: 'No',
          },
        })
          .then((res) => {
            if (res) {
              this.axios.delete(`admin/users/${this.data.uuid}`)
                .then(() => {
                  const index = this.users.findIndex(x => x.uuid === this.data.uuid)
                  this.$delete(this.users, index)
                  this.$toast.success('User deleted')
                })
                .catch(error => {
                  this.$toast.error(error)
                })
            }
          })
      },
    },
  }
</script>
